<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="6" :sm="24">-->
<!--              <a-form-item :label="$t('直播.腾讯IM群ID(发起直播单独创建一个群)')" prop="imGroupId">-->
<!--                <a-input v-model="queryParam.imGroupId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('直播.腾讯IM群ID(发起直播单独创建一个群)')" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="6" :sm="24">-->
<!--              <a-form-item :label="$t('通用.文本.创建开始时间')" prop="createBeginTime">-->
<!--                <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"-->
<!--                     :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"-->
<!--                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="6" :sm="24">-->
<!--              <a-form-item :label="$t('通用.文本.创建结束时间')" prop="createEndTime">-->
<!--                <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"-->
<!--                     :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"-->
<!--                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="6" :sm="24">-->
<!--              <a-form-item :label="$t('直播.直播用户id')" prop="userId">-->
<!--                <a-input v-model="queryParam.userId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('直播.直播用户id')" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="6" :sm="24">-->
<!--              <a-form-item :label="$t('通用.文本.创建开始时间')" prop="createBeginTime">-->
<!--                <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"-->
<!--                     :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"-->
<!--                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="6" :sm="24">-->
<!--              <a-form-item :label="$t('通用.文本.创建结束时间')" prop="createEndTime">-->
<!--                <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"-->
<!--                     :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"-->
<!--                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <template v-if="advanced">-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('直播.直播商户id')" prop="shopId">-->
<!--                  <a-input v-model="queryParam.shopId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('直播.直播商户id')" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('通用.文本.创建开始时间')" prop="createBeginTime">-->
<!--                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"-->
<!--                     :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"-->
<!--                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('通用.文本.创建结束时间')" prop="createEndTime">-->
<!--                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"-->
<!--                     :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"-->
<!--                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('直播.直播人昵称')" prop="shopName">-->
<!--                  <a-input v-model="queryParam.shopName" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('直播.直播人昵称')" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('通用.文本.创建开始时间')" prop="createBeginTime">-->
<!--                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"-->
<!--                     :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"-->
<!--                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('通用.文本.创建结束时间')" prop="createEndTime">-->
<!--                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"-->
<!--                     :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"-->
<!--                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('直播.直播人头像')" prop="shopLogo">-->
<!--                  <a-input v-model="queryParam.shopLogo" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('直播.直播人头像')" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('通用.文本.创建开始时间')" prop="createBeginTime">-->
<!--                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"-->
<!--                     :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"-->
<!--                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('通用.文本.创建结束时间')" prop="createEndTime">-->
<!--                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"-->
<!--                     :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"-->
<!--                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('直播.直播封面')" prop="thumb">-->
<!--                  <a-input v-model="queryParam.thumb" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('直播.直播封面')" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('通用.文本.创建开始时间')" prop="createBeginTime">-->
<!--                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"-->
<!--                     :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"-->
<!--                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('通用.文本.创建结束时间')" prop="createEndTime">-->
<!--                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"-->
<!--                     :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"-->
<!--                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('直播.直播主题')" prop="theme">
                  <a-input v-model="queryParam.theme" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('直播.直播主题')" allow-clear/>
                </a-form-item>
              </a-col>

          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
<!--      <div class="table-operations">-->
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['live:live:add']">-->
<!--          <a-icon type="plus" />{{$t('通用.按钮.新增')}}-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['live:live:edit']">-->
<!--          <a-icon type="edit" />{{$t('通用.按钮.修改')}}-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['live:live:remove']">-->
<!--          <a-icon type="delete" />{{$t('通用.按钮.删除')}}-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['live:live:export']">-->
<!--          <a-icon type="download" />{{$t('通用.按钮.导出')}}-->
<!--        </a-button>-->
<!--        <table-setting-->
<!--          :style="{float: 'right'}"-->
<!--          :table-size.sync="tableSize"-->
<!--          v-model="columns"-->
<!--          :refresh-loading="loading"-->
<!--          @refresh="getList" />-->
<!--      </div>-->
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="openTime" slot-scope="text, record">
          {{ parseTime(record.openTime) }}
        </span>
        <template slot="thumb" slot-scope="text, record">
          <div>
            <img v-if="record.thumb" :src="record.thumb"  style="width:60px;height:62px;" @click="handleRecordPreview(record.thumb)" alt="none"/>
          </div>
        </template>
        <span slot="closeTime" slot-scope="text, record">
          {{ parseTime(record.closeTime) }}
        </span>
        <span slot="expiredTime" slot-scope="text, record">
          {{ parseTime(record.expiredTime) }}
        </span>
        <span slot="errorCloseTime" slot-scope="text, record">
          {{ parseTime(record.errorCloseTime) }}
        </span>
        <span slot="redPacketExpiredTime" slot-scope="text, record">
          {{ parseTime(record.redPacketExpiredTime) }}
        </span>
        <span slot="redPacketBeginTime" slot-scope="text, record">
          {{ parseTime(record.redPacketBeginTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['live:live:edit']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['live:live:edit']">-->
<!--             <a-icon type="edit" />{{$t('通用.按钮.修改')}}-->
<!--          </a>-->
          <a-divider type="vertical" v-hasPermi="['live:live:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['live:live:remove']">
            <a-icon type="delete" />{{$t('通用.按钮.删除')}}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageLive,listLive, delLive } from '@/api/live/live'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import moment from 'moment';
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Live',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo : {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        imGroupId: undefined,
        userId: undefined,
        shopId: undefined,
        shopName: undefined,
        shopLogo: undefined,
        thumb: undefined,
        theme: undefined,
        intro: undefined,
        liveName: undefined,
        openTime: undefined,
        closeTime: undefined,
        expiredTime: undefined,
        effective: undefined,
        duration: undefined,
        pushUrl: undefined,
        pullUrl: undefined,
        chatNum: undefined,
        totalMoney: undefined,
        status: undefined,
        cutInfo: undefined,
        isErrorClose: undefined,
        errorCloseTime: undefined,
        classifyId: undefined,
        liveType: undefined,
        liveAddress: undefined,
        liveCityName: undefined,
        redPacketExpiredTime: undefined,
        redPacketBeginTime: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: this.$t('直播.主键id'),
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.腾讯IM群ID(发起直播单独创建一个群)'),
        //   dataIndex: 'imGroupId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.直播用户id'),
        //   dataIndex: 'userId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.直播商户id'),
        //   dataIndex: 'shopId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('直播人昵称'),
          dataIndex: 'shopName',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: this.$t('直播.直播人头像'),
        //   dataIndex: 'shopLogo',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('封面'),
          dataIndex: 'thumb',
          scopedSlots: { customRender: 'thumb' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('主题'),
          dataIndex: 'theme',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: this.$t('主题介绍'),
        //   dataIndex: 'intro',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播间名称'),
        //   dataIndex: 'liveName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('开启时间'),
          dataIndex: 'openTime',
          scopedSlots: { customRender: 'openTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('直播关闭时间'),
          dataIndex: 'closeTime',
          scopedSlots: { customRender: 'closeTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: this.$t('直播.推流过期时间'),
        //   dataIndex: 'expiredTime',
        //   scopedSlots: { customRender: 'expiredTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.直播有效时长(单位:小时)'),
        //   dataIndex: 'effective',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('直播.直播时长'),
          dataIndex: 'duration',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: this.$t('直播.推流地址'),
        //   dataIndex: 'pushUrl',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.拉流地址'),
        //   dataIndex: 'pullUrl',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.直播互动人数(峰值)'),
        //   dataIndex: 'chatNum',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.直播累计收益 '),
        //   dataIndex: 'totalMoney',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('直播.直播状态'),
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (v) {
            if (v == 1) {
              return "未开播";
            }else if (v == 2){
              return '已开播';
            }else if (v == 3){
              return '直播结束';
            }
          }
        },
        // {
        //   title: this.$t('直播.断流信息(直播断流总信息)'),
        //   dataIndex: 'cutInfo',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.是否直播流异常,1,异常 0,正常,腾讯云直播流异常时写入'),
        //   dataIndex: 'isErrorClose',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.异常时间'),
        //   dataIndex: 'errorCloseTime',
        //   scopedSlots: { customRender: 'errorCloseTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.直播分类,live_live_classify'),
        //   dataIndex: 'classifyId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.直播类型 (1:语音直播,2:视频直播)'),
        //   dataIndex: 'liveType',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.直播地址'),
        //   dataIndex: 'liveAddress',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.直播城市'),
        //   dataIndex: 'liveCityName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.红包过期时间'),
        //   dataIndex: 'redPacketExpiredTime',
        //   scopedSlots: { customRender: 'redPacketExpiredTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.红包开始领取时间'),
        //   dataIndex: 'redPacketBeginTime',
        //   scopedSlots: { customRender: 'redPacketBeginTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.创建时间'),
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('直播.备注'),
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询直播列表 */
    getList () {
      this.loading = true
     pageLive(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        imGroupId: undefined,
        userId: undefined,
        shopId: undefined,
        shopName: undefined,
        shopLogo: undefined,
        thumb: undefined,
        theme: undefined,
        intro: undefined,
        liveName: undefined,
        openTime: undefined,
        closeTime: undefined,
        expiredTime: undefined,
        effective: undefined,
        duration: undefined,
        pushUrl: undefined,
        pullUrl: undefined,
        chatNum: undefined,
        totalMoney: undefined,
        status: undefined,
        cutInfo: undefined,
        isErrorClose: undefined,
        errorCloseTime: undefined,
        classifyId: undefined,
        liveType: undefined,
        liveAddress: undefined,
        liveCityName: undefined,
        redPacketExpiredTime: undefined,
        redPacketBeginTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        if (this.queryParam.sortField==='createTime') {
          this.queryParam.sortField= 'id'
        }
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delLive(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              const message = that.$t('通用.文本.删除成功')
              that.$message.success(
                message,
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('live/live/export',
            ...that.queryParam
          , `直播_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
